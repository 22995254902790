import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import ModalPaymentCalculator from '../ModalPaymentCalculator/ModalPaymentCalculator.js';
import ModalBorrowCalculator from '../ModalBorrowCalculator/ModalBorrowCalculator.js';
import './footer.scss';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = { showPaymentCalculator: false, showBorrowCalculator: false }
    }

    setModalShowPayment = value => {
        this.setState({ showPaymentCalculator: value });
    }

    setModalShowBorrow = value => {
        this.setState({ showBorrowCalculator: value });
    }

    render() {
        const { showPaymentCalculator, showBorrowCalculator } = this.state;

        return (
            <footer>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={3} className="text-center text-sm-left mb-4 mb-md-0">
                            <h5 className="social-title">Follow</h5>
                            <div className="social-text">
                                <a href="https://www.twitter.com/applefms"><span className="mr-4"><i className="fab fa-twitter"></i></span></a>
                                <a href="https://www.facebook.com/AppleFMS"><span><i className="fab fa-facebook"></i></span></a>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={3} className="text-center text-sm-left mb-4 mb-md-0">
                            <h5 className="social-title">Services</h5>
                            <div className="social-text">
                                <div><Link to="/mortgages">Mortgages</Link></div>
                                <div><Link to="/equity">Equity Release</Link></div>
                                <div><Link to="/protection">Protection</Link></div>
                                <div><Link to="/insurance">Insurance</Link></div>
                                <div><Link to="/conveyancing">Conveyancing</Link></div>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={3} className="text-center text-sm-left mb-4 mb-md-0">
                            <h5 className="social-title">Calculators</h5>
                            <div className="social-text">
                                <div className="pointer" onClick={() => this.setModalShowPayment(true)}>Payment Calculator</div>
                                <div className="pointer" onClick={() => this.setModalShowBorrow(true)}>Borrow Calculator</div>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={3} className="text-center text-sm-left mb-4 mb-md-0">
                            <h5 className="social-title">Contact</h5>
                            <Row>
                                <Col xs={12} sm={12}>
                                    <div className="mb-4 social-text contact">
                                        <div className="footer-contact-details-icon">
                                            <i className="fas fa-phone"></i>
                                        </div>

                                        <div className="align-middle ml-0 ml-md-3">
                                            <div className="contact-text-header text-md-left">Phone</div>
                                            <div className="contact-text">07980 832992</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12}>
                                    <div className="contact mb-4 social-text">
                                        <div className="footer-contact-details-icon float-md-left">
                                            <i className="fas fa-envelope"></i>
                                        </div>

                                        <div className="align-middle ml-0 ml-md-3">
                                            <div className="contact-text-header text-md-left">Email</div>
                                            <a id="email-contact" href="mailto:info@applefms.co.uk" className="contact-text">info@applefms.co.uk</a>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="contact social-text">
                                        <div className="footer-contact-details-icon float-md-left">
                                            <i className="fas fa-map-marker-alt"></i>
                                        </div>

                                        <div className="align-middle ml-0 ml-md-3">
                                            <div className="contact-text-header text-md-left">Address</div>
                                            <span className="contact-text float-md-left text-md-left">8 Waun Deri<br/>Pembrey<br/>Burry Port<br/>SA16 OLF</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className="font-italic mt-5 mb-4 text-center">
                        Nigel David Wain t/a Apple Financial &amp; Mortgage Solutions.
                        <br/><br/>There may be a fee for arranging a mortgage. The maximum fee you would pay is £398.
                    </div>
                    <div className="font-italic mb-5 text-center">
                        The information contained in this website is subject to UK regulatory regime and is therefore intended for consumers based in the UK
                    </div>
                    <div id="social-separator"></div>
                    <div className="footer-text text-center">
                        <div><Link className="footer-link" to="/privacy-policy" onClick={this.privacyClicked}>Privacy Policy</Link></div>
                        <div>Copyright © {this.props.companyName} 2024</div>
                        <div>Designed by <a href="https://www.ercsolutions.co.uk" className="footer-link">ERC Solutions</a></div>
                    </div>
                </Container>
                <ModalPaymentCalculator
                        show={showPaymentCalculator}
                        onHide={() => this.setModalShowPayment(false)}
                    />
                <ModalBorrowCalculator
                    show={showBorrowCalculator}
                    onHide={() => this.setModalShowBorrow(false)}
                />
            </footer>
        );
    }
}

export default Footer;
