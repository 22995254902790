import React from 'react';
import Header from './Header/Header.js';
import ContactForm from './Contact/Contact.js';

function Contact() {
    return (
        <div>
            <Header/>
            <ContactForm/>
        </div>
    );
}

export default Contact;
