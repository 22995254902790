import React from 'react';
import Header from './Header/Header.js';
import Content from './Content/Content.js';
import ContactBanner from '../ContactBanner/ContactBanner.js';

function Protection() {
    return (
        <div>
            <Header/>
            <Content/>
            <ContactBanner desc="Contact us now to get your home move moving!"/>
        </div>
    );
}

export default Protection;
