import React, { Component } from 'react';
import { Container, Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';
import './navbar.scss';

class NavBar extends Component {

    setNavExpanded = (expanded) => {
        this.setState({ navExpanded: expanded });
    }

    closeNav = () => {
        this.setState({ navExpanded: false });
    }

    state = {
        expanded: {},
    }

  render() {
      let navBarClass = 'navbar-default';
      if (this.props.scrolled === true) {
          navBarClass += ' scrolled';
      }

    return (
        <Navbar onToggle={this.setNavExpanded} expanded={this.state.navExpanded} className={navBarClass} variant="dark" id="mainNav" expand="lg" fixed="top">
            <Container>
                <Navbar.Brand>
                    <NavLink to="/" className="nav-link">
                        Apple FMS
                    </NavLink>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                      <NavItem>
                          <NavLink exact={true} to="/" className="nav-link" activeClassName="active" onClick={this.closeNav}>
                              Home
                          </NavLink>
                      </NavItem>
                      <NavItem>
                          <NavLink to="/about" className="nav-link" activeClassName="active" onClick={this.closeNav}>
                              About
                          </NavLink>
                       </NavItem>
                      <NavItem>
                        <NavDropdown title="Services" id="basic-nav-dropdown">
                              <NavLink to="/mortgages" className="dropdown-item" activeClassName="active" onClick={this.closeNav}>
                                  Mortgages
                              </NavLink>
                            
                              {/*<NavLink to="/equity" className="dropdown-item" activeClassName="active" onClick={this.closeNav}>
                                  Equity Release
                              </NavLink>
                              */}
                              <NavLink to="/protection" className="dropdown-item" activeClassName="active" onClick={this.closeNav}>
                                  Protection
                              </NavLink>
                              <NavLink to="/insurance" className="dropdown-item" activeClassName="active" onClick={this.closeNav}>
                                  Insurance
                              </NavLink>
                              <NavLink to="/conveyancing" className="dropdown-item no-border" activeClassName="active" onClick={this.closeNav}>
                                  Conveyancing
                              </NavLink>
                        </NavDropdown>
                      </NavItem>
                      <NavItem>
                          <NavLink to="/contact" className="nav-link" activeClassName="active" onClick={this.closeNav}>
                              Contact
                          </NavLink>
                      </NavItem>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
  }
}

export default NavBar;
