import React, { Component } from 'react';
import './header.scss';
import { Link } from 'react-scroll';

class Header extends Component {

  render() {

    return (
        <section id="home">
            <div id="header-img" className="bg-cover"></div>
            <div className="container">
                <div className="header-text">
                    <h1>Apple Financial &amp; <br/>Mortgage Solutions</h1>
                    <div>Mortgage &amp; Insurance specialist</div>
                    <Link
                        activeClass="active"
                        to="home-about"
                        spy={true}
                        smooth={true}
                        offset={-56}
                        duration={800}
                        className="btn">
                        Find out more
                    </Link>
                </div>
            </div>
        </section>
    );
  }
}

export default Header;
