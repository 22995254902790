import React, { Component } from 'react';
import './services.scss';
import { Link } from 'react-router-dom';

class Services extends Component {
      render() {
          return (
              <section id="services" className="section-standard">
                  <div className="bg-cover"></div>
                    <div className="container">
                        <h2 className="mb-3 text-center section-title-light">Our Services</h2>
                        <div className="section-header-separator"></div>
                        <div className="row mb-md-5">
                            <div className="col-md-3 text-center mb-3 mb-md-0">
                                <Link to="/mortgages" className="services-link">
                                    <span className="fa-stack fa-2x mb-2">
                                      <i className="fas fa-circle fa-stack-2x"></i>
                                      <i className="fas fa-building fa-stack-1x fa-inverse"></i>
                                    </span>
                                    <h6 className="mb-2 service-title">Whole of market mortgage broker</h6>
                                    <div className="services-text mb-4">At Apple Financial &amp; Mortgage Solutions we compare 1000’s of mortgage deals, many not available on the high street and recommend the right one to suit your specific needs.
                                </div>
                                </Link>
                            </div>
                            {/*<div className="col-md-4 text-center mb-3 mb-md-0">
                                <Link to="/equity" className="services-link">
                                    <span className="fa-stack fa-2x mb-3">
                                      <i className="fas fa-circle fa-stack-2x"></i>
                                      <i className="fas fa-home fa-stack-1x fa-inverse"></i>
                                    </span>
                                    <h6 className="mb-2 service-title">Equity Release</h6>
                                    <div className="services-text mb-4">Independent advice to suit your requirements.</div>
                                </Link>
                            </div>
                            */}
                            <div className="col-md-3 text-center mb-3 mb-md-0">
                                <Link to="/protection" className="services-link">
                                    <span className="fa-stack fa-2x mb-3">
                                      <i className="fas fa-circle fa-stack-2x"></i>
                                      <i className="fas fa-umbrella fa-stack-1x fa-inverse"></i>
                                    </span>
                                    <h6 className="mb-2 service-title">Protection</h6>
                                    <div className="services-text mb-4">We can ensure you are protected for your new mortgage.</div>
                                </Link>
                            </div>
                            <div className="col-md-3 text-center mb-3 mb-md-0">
                                <Link to="/insurance" className="services-link">
                                    <span className="fa-stack fa-2x mb-3">
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-shield-alt fa-stack-1x fa-inverse"></i>
                                    </span>
                                    <h6 className="mb-2 service-title">Insurance</h6>
                                    <div className="services-text mb-4">We can arrange your building insurance.</div>
                                </Link>
                            </div>
                            <div className="col-md-3 text-center mb-3 mb-md-0">
                                <Link to="/conveyancing" className="services-link">
                                    <span className="fa-stack fa-2x mb-3">
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-pound-sign fa-stack-1x fa-inverse"></i>
                                    </span>
                                    <h6 className="mb-2 service-title">Conveyancing</h6>
                                    <div className="services-text mb-4">Let us take the stress out of your purchase.</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
          );
      }
 }

 export default Services;
