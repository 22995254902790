import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import map from '../../../assets/images/map.jpg';
import { Link } from 'react-router-dom';
import './contact.scss';

class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            name: '', phone: '', email: '', subject: '', message: '', bestTime: '', bestMethod: '', consent: false 
        };

        this.submitContact = this.submitContact.bind(this);
    }

    handleChange = e => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    submitContact = e => {
        e.preventDefault();

        const { email, phone, name, subject, message, bestTime, bestMethod, consent } = this.state;

        console.log(consent)

        if (!consent) {
            alert('You must consent to the data use policy before proceeding');
            return;
        }

        if (email === '' && phone === '') {
            alert('You must enter at least one piece of contact information');
            return;
        }

        if (!message) {
            alert('You must enter a message.');
            return;
        }

        var data = {
            name: name,
            phone: phone,
            email: email,
            subject: subject,
            message: message,
            bestTime: bestTime,
            bestMethod: bestMethod
        };

        document.getElementById('message-container').classList.remove('hide');
        document.getElementById('contact-page-form').style.display = 'none';

        console.log('Sending email...')
        fetch('https://ddegq5cge8.execute-api.eu-west-1.amazonaws.com/prod/contact-us', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          crossDomain: 'true',
          body: JSON.stringify(data)
        })
        .then(
            (result) => {
                document.getElementById('contact-loader').classList.add('hide');
                document.getElementById('contact-success').style.display = 'block';
            }
        );
    }

    render() {
        const { name, phone, email, subject, message, bestTime, bestMethod, consent } = this.state;

        return (
            <section id="contactForm">
            <div>
                <h2 className="mb-3 text-center section-title-dark">Request a call back</h2>
                <div className="section-header-separator"></div>
                <div className="container">
                    <Row>
                        <Col md={{ span: 4, offset: 1 }} className="mb-4 mb-md-0 order-last order-md-first">
                            <h5 className="mb-4">General enquiries</h5>

                            <div className="mb-3">
                                <div>
                                    <span className="contact-details-icon">
                                        <i className="fas fa-phone mr-3"></i>
                                    </span>
                                    <span className="contact-text">07980 832992</span>
                                </div>
                            </div>

                            <div className="mb-3">
                                <span className="contact-details-icon">
                                    <i className="fas fa-envelope mr-3"></i>
                                </span>
                                <a id="email-contact-form" href="mailto:info@applefms.co.uk" className="contact-text">info@applefms.co.uk</a>
                            </div>

                            <div className="contact-text">
                                <span className="contact-details-icon float-left">
                                    <i className="fas fa-map-marker-alt mr-3"></i>
                                </span>
                                <span className="float-left">8 Waun Deri<br/>Pembrey<br/>Burry Port<br/>SA16 OLF</span>
                            </div>
                        </Col>
                        <Col md={7} className="order-first order-md-last mb-4 mb-md-0">
                            <h5 className="mb-4">Contact us</h5>
                            <form id="contact-page-form" className="text-center" onSubmit={this.submitContact}>
                                <div className="form-row form-group">
                                    <Col>
                                        <div className="input-wrapper">
                                            <i className="fas fa-user icon-input centered mr-4"></i>
                                            <input type="text" className="form-control" id="name" name="name" placeholder="Your name" value={name} onChange={this.handleChange}/>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="input-wrapper">
                                            <i className="fas fa-envelope icon-input centered mr-4"></i>
                                            <input type="email" className="form-control" id="email" name="email" placeholder="Email address" value={email} onChange={this.handleChange}/>
                                        </div>
                                    </Col>
                                </div>
                                <div className="form-row form-group">
                                    <Col>
                                        <div className="input-wrapper">
                                            <i className="fas fa-phone icon-input centered mr-4"></i>
                                            <input type="text" className="form-control" id="phone" name="phone" placeholder="Phone no" value={phone} onChange={this.handleChange}/>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="input-wrapper">
                                            <i className="fas fa-book-open icon-input centered mr-4"></i>
                                            <input type="text" className="form-control" id="subject" name="subject" value={subject} placeholder="Subject" onChange={this.handleChange}/>
                                        </div>
                                    </Col>
                                </div>
                                <div className="form-row form-group">
                                    <Col>
                                        <div className="input-wrapper">
                                            <i className="fas fa-comments icon-input top mr-4"></i>
                                            <textarea rows="10" type="text" className="form-control" id="message" name="message" placeholder="Message" value={message} onChange={this.handleChange}></textarea>
                                        </div>
                                    </Col>
                                </div>
                                <div className="form-row form-group">
                                    <Col>
                                        <div className="input-wrapper">
                                            <i className="fas fa-clock icon-input centered mr-4"></i>
                                            <select className="form-control" id="bestTime" name="bestTime" value={bestTime} onChange={this.handleChange}>
                                                <option hidden>Best time to contact</option>
                                                <option value="Morning">Morning</option>
                                                <option value="Afternoon">Afternoon</option>
                                                <option value="Evening">Evening</option>
                                                <option value="Anytime">Anytime</option>
                                            </select>
                                        </div>
                                    </Col>
                                </div>
                                <div className="form-row form-group">
                                    <Col>
                                        <div className="input-wrapper">
                                            <i className="fas fa-id-card icon-input centered mr-4"></i>
                                            <select className="form-control" id="bestMethod" name="bestMethod" value={bestMethod} onChange={this.handleChange}>
                                                <option hidden>Best method to contact</option>
                                                <option value="Phone">Phone</option>
                                                <option value="Email">Email</option>
                                            </select>
                                        </div>
                                    </Col>
                                </div>
                                <div className="form-row form-group">
                                    <Col>
                                        <div className="form-check">
                                            <input type="checkbox" id="consent" name="consent" className="form-check-input" value={consent} onChange={this.handleChange} />
                                            <label title="" htmlFor="consent" className="contact-text text-left ml-2">By submitting this data, I am consenting to the use of my data in line with the <Link id="contact-privacy" to="/privacy-policy">Privacy Policy</Link>.</label>
                                        </div>
                                    </Col>
                                </div>
                                <div className="form-row form-group">
                                    <div className="col text-center text-lg-center">
                                        <button className="btn-k2" type="submit">Send Message</button>
                                    </div>
                                </div>
                            </form>
                            <div id="message-container" className="d-flex justify-content-center hide">
                                <div id="contact-loader">
                                    <div className="loader"></div>
                                    <div className="loader-text">Sending message...</div>
                                </div>
                                <div id="contact-success">
                                    Thanks for contacting us!  A member of our team will be in touch with you shortly.
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <Image id="map" src={map} fluid/>
        </section>
        );
    }
 }

 export default Contact;
