import React, { Component } from 'react';
import './header.scss';

class Header extends Component {

  render() {

    return (
        <section id="about-header">
            <div id="about-header-img" className="bg-cover"></div>
            <div className="container">
                <div className="header-text">
                    <h1>About Us</h1>
                    <div>About Apple Financial & Mortgage Solutions</div>
                </div>
            </div>
        </section>
    );
  }
}

export default Header;
