import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar.js';
import Home from './components/Home/Home.js';
import Footer from './components/Footer/Footer.js';
import Mortgages from './components/Mortgages/Mortgages.js';
import About from './components/About/About.js';
import Contact from './components/Contact/Contact.js';
import Protection from './components/Protection/Protection.js';
import Insurance from './components/Insurance/Insurance';
import Conveyancing from './components/Conveyancing/Conveyancing';
import ScrollToTop from './components/ScrollToTop';
import NotFound from './NotFound.js';
import Privacy from './components/Privacy/Privacy.js';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { scroll: false };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUnMount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.pageYOffset > 0) {
            this.setState({ scroll: true });
        } else {
            this.setState({ scroll: false });
        }
    }

    render() {
        const { scroll } = this.state;
        return (
            <Router>
                <ScrollToTop>
                    <Navbar scrolled={scroll}/>

                    <Switch>
                        <Route exact path="/" render={(props) => <Home {...props} isAuthed={true} />}/>
                        <Route path="/about" component={About}/>
                        <Route path="/mortgages" component={Mortgages}/>
                        {/*<Route path="/equity" component={Equity}/>*/}
                        <Route path="/contact" component={Contact}/>
                        <Route path="/protection" component={Protection}/>
                        <Route path="/insurance" component={Insurance}/>
                        <Route path="/conveyancing" component={Conveyancing}/>
                        <Route path="/privacy-policy" component={Privacy}/>
                        <Route component={NotFound} />
                    </Switch>
                    <Footer/>
                </ScrollToTop>
            </Router>
        )
    }
}

export default App;
