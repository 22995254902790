import React, { Component } from 'react';
import './header.scss';

class Header extends Component {

    render() {
        return (
            <section id="mortgages-header" className="section-standard">
                <div id="mortgages-header-img" className="bg-cover"></div>
                <div className="container">
                    <div className="header-text">
                        <h1>Mortgages</h1>
                        <div>How can we help you</div>
                    </div>
                </div>
                <div>
                </div>
            </section>
        );
    }
}

export default Header;
