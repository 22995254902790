import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './content.scss';

class Content extends Component {
    render() {
      return (
          <>
              <section id="insurance" className="section-standard">
                    <Container>
                        <h2 className="mb-3 text-center section-title-dark">Insurance</h2>
                        <div className="section-header-separator"></div>
                        <div className="about-text text-gray">
                            <div>
                                If you own your own home, you’ll need to have buildings cover just in case your home is damaged and needs a repair. It’s usually a condition of your mortgage and, if you’re a landlord, it’s your responsibility – not your tenants.
                            </div>
                        </div>
                    </Container>
                </section>
                <section id="insurance-one" className="section-standard">
                    <Container>
                        If you own your own home this sort of insurance is highly recommended.
                    </Container>
                </section>
                <section id="insurance-two" className="section-standard">
                    <Container>
                        <div className="mb-5 text-center">Home insurance is a general term used to describe two very different types of insurance:</div>
                        <Row className="mb-4">
                            <Col>
                                <div className="text-center">
                                    <i className="fas fa-building"></i>
                                    <div className="title">Building insurance</div>
                                    <div className="desc">For permanent fixtures and fittings, like kitchens and bathrooms.</div>
                                </div>
                            </Col>
                            <Col>
                                <div className="text-center">
                                    <i className="fas fa-home"></i>
                                    <div className="title">Contents insurance</div>
                                    <div className="desc">For things you keep in your home, like furniture, TVs, personal belongings and some types of flooring including carpets.</div>
                                </div>
                            </Col>
                        </Row>
                        <div className="text-center">You can buy both types of insurance separately, or in many cases, you can get them as a joint policy from one insurance company.</div>
                        <br/>
                        <div className="text-center">Apple FMS has access to many 5 star defacto rated insurers so let us run through your insurance needs today.</div>
                    </Container>
                </section>
                <section id="insurance-policy" className="section-standard">
                    <Container className="vertical-center">
                        We can search through a range of policies to find the one that suits you best from our extensive list of insurers.
                    </Container>
                </section>
            </>
      );
    }
 }

 export default Content;
