import React, { Component } from 'react';
import './header.scss';

class Header extends Component {

  render() {

    return (
        <section id="contact-header">
            <div id="contact-header-img" className="bg-cover"></div>
            <div className="container">
                <div className="header-text">
                    <h1>Contact</h1>
                    <div>To get the all expert advice or financial products you need, starts with a simple call or email.</div>
                </div>
            </div>
        </section>
    );
  }
}

export default Header;
