import React from 'react';
import Header from './Header/Header.js';
import Services from './Services/Services.js';
import Calculator from '../Calculator/Calculator.js';
import ContactBanner from '../ContactBanner/ContactBanner.js';
import About from './About/About.js';

function Home() {
    return (
        <div>
            <Header/>
            <About/>
            <Services/>
            <Calculator/>
            <ContactBanner desc="Get in touch, we'd love to hear from you"/>
        </div>
    );
}

export default Home;
