import { Modal, Form, Container } from 'react-bootstrap';
import React, { Component } from 'react';
import { toCurrency } from '../../helpers.js';
export default class ModalBorrowCalculator extends Component {
  constructor(props) {
    super(props);

    this.state = { salaryOne: '', salaryTwo: '', max: '' };
  }

  updateMax() {
    let { salaryOne, salaryTwo } = this.state;

    salaryOne = salaryOne ? salaryOne.replace(/,/g, '') : '0';
    salaryTwo = salaryTwo ? salaryTwo.replace(/,/g, ''): '0';

    this.setState({ max: (parseInt(salaryOne) + parseInt(salaryTwo)) * 4.5 });
  }

  handleChange = event => {
      const { id, value } = event.target;

      let tempVal = value.toString().replace(/,/g, '');
      if (!/^\d+$/.test(tempVal) && tempVal !== '') {
        return;
      }

      this.setState({
        [id]: value
      }, () => {
         this.updateMax();
      });
    }

  render() {
    const { salaryOne, salaryTwo, max } = this.state;

    return(
        <Modal
          {...this.props}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100">
              <h3 className="mb-3 text-center section-title-dark">Borrow Calculator</h3>
              <div className="section-header-separator"></div>   
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Form>
                <Form.Group controlId="salaryOne">
                  <Form.Label>
                    Applicant 1 salary
                  </Form.Label>
                  <div className="control-wrapper left-side">
                    <div>£</div>
                    <Form.Control inputmode="numeric" maxlength="11" ype="text" value={toCurrency(salaryOne)} placeholder="0" onChange={this.handleChange} />
                  </div>
                </Form.Group>

                <Form.Group controlId="salaryTwo">
                  <Form.Label>
                    Applicant 2 salary
                  </Form.Label>
                  <div className="control-wrapper left-side">
                    <div>£</div>
                    <Form.Control inputmode="numeric" type="text" maxlength="11" value={toCurrency(salaryTwo)} placeholder="0" onChange={this.handleChange} />
                  </div>
                </Form.Group>

                <Form.Group controlId="max">
                  <Form.Label>
                    Maximum borrowing
                  </Form.Label>
                  <div className="control-wrapper left-side">
                    <div>£</div>
                    <Form.Control value={toCurrency(max)} placeholder="0" readOnly disabled/>  
                  </div>
                 </Form.Group>
              </Form>
              <div className="text-center text-15 text-gray mt-4">The guidance on this calculator is for illustrative purposes only</div>
            </Container>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      );
    };
  }