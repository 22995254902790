import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import aboutContent from '../../../assets/images/about-content.jpg';
import './content.scss';

class Content extends Component {
      render() {
          return (
              <section id="about" className="section-standard">
                    <div className="container">
                        <h2 className="mb-3 text-center section-title-dark">About Us</h2>
                        <div className="section-header-separator"></div>
                        <Row>
                            <Col>
                                <div className="about-text text-md-left text-center text-gray vertical-center mb-5 mb-md-0">
                                    Thank you for considering Apple FMS.
                                    <br/><br/>
                                    Looking for your first or next home can be stressful enough without having to hunt for the right mortgage. Most Banks and Building Societies are great at telling you about their mortgages but there are thousands of mortgage deals available, including some mortgage deals which are not available on the High Street and can only be accessed by whole of market advisors like Apple Financial and Mortgage Solutions.
                                </div>
                            </Col>
                            <Col md={5} className="order-first order-md-last">
                                <Image src={aboutContent} fluid/>
                            </Col>
                        </Row>
                    </div>
                </section>
          );
      }
 }

 export default Content;
