import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import './repossessed.scss';

class Repossessed extends Component {

      render() {
          return (
              <section id="repossessed" className="section-standard vertical-center">
                <Container className="text-center">
                    <div className="repossessed">YOUR HOME MAY BE REPOSSESSED IF YOU DO NOT KEEP UP REPAYMENTS ON YOUR MORTGAGE</div>
                    <br/>
                    <div>Nigel David Wain t/a Apple Financial &amp; Mortgage Solutions.</div>
                    <br/>
                    There may be a fee for arranging a mortgage. The maximum fee you would pay is £398 but equity release charges may differ.
                </Container>
            </section>
          );
      }
 }

 export default Repossessed;
