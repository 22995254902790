import React from 'react';
import Header from './Header/Header.js';
import Content from './Content/Content.js';

function Conveyancing() {
    return (
        <div>
            <Header/>
            <Content/>
        </div>
    );
}

export default Conveyancing;
