import React from 'react';
import './privacy.scss';

function Privacy() {
    return (
        <div id="privacy" className="section-standard">
            <h3 className="text-center mb-4">Privacy Policy</h3>
            This privacy policy outlines how Apple FMS uses and protects any information that you provide when you use this website. DATA CONTROLLER NAME is a controller of your personal data under the Data Protection Act 2018 and the General Data Protection Regulation. We are committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, this information will only be used in accordance with this privacy statement.
            <br/><br/>
            <h5>Who We Are</h5>
            <br/>
            Name:   Apple Financial &amp; Mortgage Solutions
            <br/><br/>
            Address: 8 Waun Deri, Pembrey, Burry Port, Carmarthenshire. SA16 0LF.
            <br/><br/>
            Contact Email Address:  <a href="info@applefms.co.uk">info@applefms.co.uk</a>     
            <br/><br/>
            <h5>What information is being collected?</h5>
            <br/>
            We are collecting information that is provided through the contact form on this website, as well as data collected from cookies on our website.
            <br/><br/>
            This data comprises of your name, email address, phone number and product interests.
            <br/><br/>
            <h5>How will we use the information about you?</h5>
            <br/>
            We process personal information for certain legitimate business purposes, which include some or all of the following:
            <br/><br/>
            By providing this data, you have registered interest in us contacting you using the contact details provided to discuss the products and services listed on our website
            To update and maintain our internal records.
            To track website usage in order to make improvements to the website.
            We may periodically send promotional emails containing information which we feel may be of interest to you using the email address which you have provided. All promotional emails will include an unsubscribe link should you wish to opt out of receiving email marketing. If you have any concerns regarding the opt out process, please contact the data controller using the contact details available at the bottom of this privacy policy.
            It should be noted that you have no legal or contractual obligation to provide your personal data for the purposes listed above, and there are no consequences to you for this data not being provided outside of the purposes of the processing listed above not being fulfilled.
            <br/><br/>
            This data will not be used for any automated decision making or profiling purposes.
            <br/><br/>
            <h5>Data Retention</h5>
            <br/>
            We will retain this data for a period of 6 years for use in the legitimate business purposes listed above.
            <br/><br/>
            You may choose to opt out of the processing of your data at any time by contacting us on the details listed in the ‘Who We Are’ section of this policy. We may retain some of your information to ensure that we can keep a record to identify that you wish no further processing of your data to take place, and to comply with any legal and/or regulatory responsibilities we hold.
            <br/><br/>
            If any business is transacted with the company, the new data retention period that supersedes any previously agreed retention period will be outlined to you as part of this process, and we will also supply you with the reasoning for this new retention period.
            <br/><br/>
            <h5>Who will have access to this data</h5>
            <br/>
            This data can be accessed by members of Apple FMS, our lender and provider partners and also by staff members at our Principal Firm The Right Mortgage Ltd, as we may store customer records on our shared back office system.
            <br/><br/>
            <h5>Your rights to our processing of your data</h5>
            <br/>
            Under the General Data Protection Regulation, you have the following rights:
            <br/><br/>
            The right to know if we are processing your personal data, what data we are processing and why we are processing it- which we have detailed above.
            <br/><br/>
            The right to access confirmation that we are processing your data and access the personal data we hold for you. The way to request this information is detailed in the ‘Subject Access Requests’ section of this policy.
            <br/><br/>
            The right to request correction of any inaccurate personal data that we process relating to yourself. This can be requested in writing using the contact details listed in the ‘Who We Are’ section of this policy.
            <br/><br/>
            The right to obtain and reuse your personal data for your own purposes across different services.
            <br/><br/>
            The right to object to:
            <br/><br/>
            processing based on legitimate interests or the performance of a task in the public interest/exercise of official authority (including profiling);
            direct marketing (including profiling)
            The right to request that we delete personal data that we process relating to you if:
            <br/><br/>
            We no longer need the data
            If you withdraw your consent for us to process your personal data and we don’t have another legal basis to hold this data
            If you object to our processing and we don’t have a legitimate ground to process it
            If we are processing your personal data unlawfully
            If we must delete the data due to a legal obligation
            The right to request that we restrict our processing of your personal data if:
            <br/><br/>
            You contest the accuracy of the data
            Our processing is unlawful but you don’t want us to delete the data
            We don’t need to process your personal data anymore but you need it for a legal claim
            You object to our processing of your personal data on the basis of our legitimate interest
            If you wish to enact any of these rights, please contact us using the details in the ‘who we are’ section. For further information on these rights, please visit the ICO website.
            <br/><br/>
            <h5>Subject Access Requests</h5>
            <br/>
            You may request a copy of the information we hold for you through a subject access request. This can be done by contacting us using the details listed in the ‘who we are’ section, detailing which information you would like access to. There is usually no cost, and which will be complied with within 1 month.  Where the request is considered to be manifestly unfounded or excessive, we may charge administrative costs or refuse the request. If you are unhappy with the response from FIRM NAME, and this cannot be resolved directly by contacting us, please contact the Information Commissioners Office.
            <br/><br/>
            Complaints related to data processing
            <br/><br/>
            If you are unhappy with the way in which we are processing your data, please contact us directly in the first instance to resolve this issue. If this issue cannot be resolved directly by contacting us, please contact the Information Commissioners Office.      
            <br/><br/>
            <h5>Our Use of Cookies</h5>
            <br/>
            Our Site may place and access certain first party Cookies on your computer or device. First party Cookies are those placed directly by us for our use only. We use Cookies to facilitate and improve your experience of our Site and to provide and improve our products AND services. We have carefully chosen these Cookies and have taken steps to ensure that your privacy and personal data is protected and respected at all times.
            <br/><br/>
            All Cookies used by and on Our Site are used in accordance with current Cookie Law.
            <br/><br/>
            Before Cookies are placed on your computer or device, you will be shown a prompt e.g. cookie pop up requesting your consent to set those Cookies. By giving your consent to the placing of Cookies you are enabling us to provide the best possible experience and service to you. You may, if you wish, deny consent to the placing of Cookies; however certain features of Our Site may not function fully as a result.
            <br/><br/>
            Certain features of Our Site depend on Cookies to function. Cookie Law deems these Cookies to be “strictly necessary”. These Cookies are shown below in section 13.5. Your consent will not be sought to place these Cookies, but it is still important that you are aware of them. You may still block these Cookies by changing your internet browser’s settings as detailed below in section 13.9, but please be aware that Our Site may not work properly if you do so. We have taken great care to ensure that your privacy is not at risk by allowing them.
            <br/><br/>
            No Cookies will be placed on your computer or device:
            <br/><br/>
            In addition to the controls that we provide, you can choose to enable or disable Cookies in your internet browser. Most internet browsers also enable you to choose whether you wish to disable all cookies or only third-party cookies. By default, most internet browsers accept cookies but this can be changed. For further details, please consult the help menu in your internet browser or the documentation that came with your device.
            <br/><br/>
            You can choose to delete cookies on your computer or device at any time, however you may lose any information that enables you to access our site more quickly and efficiently including, but not limited to, login and personalisation settings.
            <br/><br/>
            It is recommended that you keep your internet browser and operating system up-to-date and that you consult the help and guidance provided by the developer of your internet browser and manufacturer of your computer or device if you are unsure about adjusting your privacy settings.
            <br/><br/>
            <b>Know Your Cookies</b>
            <br/><br/>
            All Cookies used by and on Our Site are used in accordance with current Cookie Law. We may use some or all of the following types of Cookie:
            <br/><br/>
            <b>Strictly Necessary Cookies</b>
            <br/><br/>
            A cookie falls into this category if it is essential to the operation of our website. Strictly necessary cookies may, for example, be required for functions such as logging in, storing items in a shopping basket, or enabling payment transactions.
            <br/><br/>
            <b>Analytics Cookies</b>
            <br/><br/>
            Understanding how users use our website can be extremely valuable. Analytics cookies provide insights into many factors such as how users are navigating around the site and what features they are using. Analytics cookies may often be set by third parties, but not always.
            <br/><br/>
            <b>Functionality Cookies</b>
            <br/><br/>
            Many websites offer some level of personalisation and functionality cookies play a key role here. For auditing purposes, however, it is important not to confuse these with the strictly necessary variety. If the site can be used properly without the cookie, it isn’t strictly necessary.
            <br/><br/>
            <b>Targeting Cookies</b>
            <br/><br/>
            It is important to know when and how often someone visits our website, and which parts of it they have used (including which pages they have visited and which links they have followed). As with analytics cookies, targeting cookies allow us to better understand our users, enabling us to make our site and, more importantly, the advertising on it more relevant to those users’ interests. Targeting cookies may often be set by third parties.
            <br/><br/>
            <b>First-Party Cookies</b>
            <br/><br/>
            As the name suggests, these cookies are placed directly by our website (as opposed to those placed by third-party services, for which see below). Most, if not all, of your strictly necessary and functionality cookies will likely be first-party cookies.
            <br/><br/>
            <b>Third-Party Cookies</b>
            <br/><br/>
            These cookies are placed by third parties providing services such as advertising and analytics. Analytics and targeting cookies are common types of third-party cookie as such work is often not undertaken in-house.
            <br/><br/>
            <b>Persistent Cookies</b>
            <br/><br/>
            Any of the cookies listed above may be a persistent cookie. Persistent cookies are those which remain active on a user’s computer or device for a predetermined period of time and are activated when that user visits your website
            <br/><br/>
            <h5>Links to other websites</h5>
            <br/>
            Our website may contain links to third party websites. It should be noted that we do not have any control over these websites and so we are not responsible for the protection and privacy of any information which you provide whilst visiting such sites, which are not governed by this privacy statement. For this information, please consult the privacy statement applicable to the website in question.
            <br/><br/>
            <h5>What to do if you have a complaint about our service:</h5>
            <br/>
            If you wish to register a complaint, please contact:
            <br/><br/>
            - By writing: The Right Mortgage, St Johns Court, 70 St Johns Close, Knowle, B93 0NH.
            <br/><br/>
            - By phone: 01564 732744
            <br/><br/>
            A summary of our internal complaints handling procedure is available on request. If we cannot settle your complaint with us, you may be entitled to refer it to the Financial Ombudsman Service.
        </div>
    );
}

export default Privacy;
