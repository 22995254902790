import React from 'react';
import Header from './Header/Header.js';
import Content from './Content/Content.js';
import ContactBanner from '../ContactBanner/ContactBanner.js';

function About() {
    return (
        <div>
            <Header/>
            <Content/>
            <ContactBanner desc="Get in touch, we'd love to hear from you"/>
        </div>
    );
}

export default About;
