import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './contactBanner.scss';

class ContactBanner extends Component {

    contactClick = () => {
        this.props.history.push('/contact')
    }

    render() {
        return (
            <section id="contact" className="section-standard vertical-center">
            <div className="img"></div>
            <Container className="text-center">
                <div className="loud-text mb-4">{this.props.desc}</div>
                <button className="btn" onClick={this.contactClick}>Contact</button>
            </Container>
        </section>
        );
    }
 }

 export default withRouter(ContactBanner);
