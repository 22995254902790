import { Modal, Form, Row, Col, Container } from 'react-bootstrap';
import React, { Component } from 'react';
import { toCurrency } from '../../helpers.js'

export default class ModalPaymentCalculator extends Component {

  constructor(props) {
    super(props);

    this.MortgageType = { "repayment": 0, "interest": 1, };
    this.state = { amount: '', rate: '', term: '', total: '', selected: this.MortgageType.repayment };
  }

  updateTotal() {
    let { amount, rate, term, selected } = this.state;

    if (amount && rate && term) {
      amount = amount ? amount.replace(/,/g, '') : '0';

      if (selected === this.MortgageType.repayment) {
        let noMonths = parseInt(term) * 12;
        let monthlyInterestRate = (rate / 100) / 12;
        let result = Math.pow(monthlyInterestRate + 1, noMonths);
        let total = Math.round(((monthlyInterestRate * result) / (result - 1)) * amount);
        
        total = !isNaN(total) ? total : 0;

        this.setState({ total: total });
      } else {
        let total = Math.round(amount * (rate / 100) /12);
        total = !isNaN(total) ? total : 0;

        this.setState({ total: total });
      }
    }
  }

  handleChange = event => {
    const { id, value } = event.target;

    console.log(id)
    let tempVal = value.toString().replace(/,/g, '');
    if (id !== 'rate' && !/^\d+$/.test(tempVal) && tempVal !== '') {
      return;
    }

    this.setState({
      [id]: value
    }, () => {
      this.updateTotal();
    });
}

  handleMortgageClick = mortgageType => {
    this.setState({ selected: mortgageType }, () => {
      this.updateTotal();
    });
  }

  render() {
    const { amount, rate, term, total, selected } = this.state;
    return(
        <Modal
          {...this.props}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100">
              <h3 className="mb-3 text-center section-title-dark">Payment Calculator</h3>
              <div className="section-header-separator"></div>   
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Form className="px-3">
                <Form.Group controlId="amount">
                  <Form.Label>
                    Loan Amount
                  </Form.Label>
                  <div className="control-wrapper left-side">
                    <div>£</div>
                    <Form.Control onChange={this.handleChange} placeholder="0" inputMode="numeric" maxlength="11" type="text" value={toCurrency(amount)}/>
                  </div>
                </Form.Group>

                <Form.Group controlId="rate">
                  <Form.Label>
                    Rate
                  </Form.Label>
                    <div className="control-wrapper right-side">
                      <Form.Control type="number" value={rate} placeholder="0" onChange={this.handleChange} />
                      <div>%</div>
                    </div>
                </Form.Group>

                <Form.Group controlId="term">
                  <Form.Label>
                    Term
                  </Form.Label>
                    <div className="control-wrapper right-side">
                      <Form.Control type="number" maxlength="3" value={term} placeholder="0" onChange={this.handleChange} />
                      <div>years</div>
                    </div>
                </Form.Group>
                <Form.Group className="my-4">
                  <Row>
                    <Col className="col-6 d-flex justify-content-center text-center">
                      <div className={"image-btn" + (selected === this.MortgageType.repayment ? ' active' : '')} onClick={() => this.handleMortgageClick(this.MortgageType.repayment)}>
                        <div className="icon fas fa-chart-area"></div>
                        <div className="text">Repayment</div>
                      </div>
                    </Col>
                    <Col className="col-6 d-flex justify-content-center text-center">
                      <div className={"image-btn" + (selected === this.MortgageType.interest ? ' active' : '')} onClick={() => this.handleMortgageClick(this.MortgageType.interest)}>
                        <div className="icon fas fa-percentage"></div>
                        <div className="text">Interest only</div>
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group controlId="total">
                  <Form.Label>
                    Monthly payment
                  </Form.Label>
                  <div className="control-wrapper left-side">
                    <div>£</div>
                    <Form.Control type="text" value={toCurrency(total)} placeholder="0" readOnly disabled />
                  </div>
                </Form.Group>
              </Form>
              <div className="text-center text-small text-gray mt-5">The guidance on this calculator is for illustrative purposes only</div>
            </Container>
          </Modal.Body>
          <Modal.Footer className="text-center">
          </Modal.Footer>
        </Modal>
      );
    };
  }