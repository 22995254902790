import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import './content.scss';

class Content extends Component {
    render() {
      return (
          <>
              <section id="protection" className="section-standard">
                    <Container>
                        <h2 className="mb-3 text-center section-title-dark">The type of insurance policy you might need depends on your situation</h2>
                        <div className="section-header-separator"></div>
                        <div className="about-text text-gray">
                            <div className="mb-5">
                                A life insurance policy will pay your dependents a lump sum or regular payments if you die unexpectedly. If you have a partner or children, life insurance can provide you with the reassurance that they will be able to cope financially without you.
                                <br/><br/>
                                Income protection insurance is designed to support you financially if you can’t work due to illness or injury and your income drops. This type of policy is particularly relevant for anyone who is self-employed and wouldn’t get sick pay.
                                <br/><br/>
                                You might consider getting short-term income protection insurance. This type of policy will pay out a monthly sum, for a set period of time, if you lose your source of income due to illness, injury or redundancy.
                                <br/><br/>
                                You might consider critical illness cover, which will cover you in case you get a specific type of life changing condition.
                                <br/><br/>
                                At Apple FMS we have access to the top life insurance and income protection providers so let us go through your insurance needs with a no obligation meeting. 
                            </div>
                        </div>
                    </Container>
                </section>
                <section id="protection-insurance" className="section-standard">
                    <Container className="vertical-center">
                        Payment protection insurance will support you if illness or redundancy means you can’t meet regular payments of your debts.
                    </Container>
                </section>
            </>
      );
    }
 }

 export default Content;
