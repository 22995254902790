import React, { Component } from 'react';
import './about.scss';

class About extends Component {
      render() {
          return (
              <section id="home-about" className="section-standard">
                    <div className="container">
                        <h2 className="mb-3 text-center section-title-dark">Welcome</h2>
                        <div className="section-header-separator"></div>
                        <div className="about-text text-gray">
                            <div className="mb-5">
                                Welcome to Apple Financial and Mortgage Solutions. The business was established to build on industry experience obtained and to offer a fresh outlook on the mortgage market.
                                <br/><br/>
                                Apple Financial and Mortgage Solutions doesn't just provide whole of market mortgage advice, you get guidance and help from start to finish from the initial advice to completing the paperwork and much more from a mortgage broker with over 30 years experience.
                            </div>
                            <div className="about-icons">
                                <div className="row">
                                    <div className="col-md-3 mb-4 mb-md-0">
                                        <div className="text-center">
                                            <i className="fas fa-user-tie"></i>
                                            <div className="title">Individual advice</div>
                                            <div className="desc">You get more than just no obligation mortgage advice in the comfort of your own home from a qualified mortgage and insurance specialist with over 30 years experience.</div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-4 mb-md-0">
                                        <div className="text-center">
                                            <i className="fas fa-search"></i>
                                            <div className="title">Full market mortgage finder</div>
                                            <div className="desc">At Apple Financial & Mortgage Solutions we compare 1000’s of mortgage deals, many not available on the high street and recommend the right one to suit your specific needs.</div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-4 mb-md-0">
                                        <div className="text-center">
                                            <i className="fas fa-briefcase"></i>
                                            <div className="title">Online Client Portal</div>
                                            <div className="desc">No need to keep folders with hundreds of pages of mortgage and insurance paperwork. All your documents are kept in our ‘CLIENT PORTAL’ which you can access 24hours a day, 365 days of the year.</div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-4 mb-md-0">
                                        <div className="text-center">
                                            <i className="fas fa-file-signature"></i>
                                            <div className="title">Conveyancing process</div>
                                            <div className="desc">No need to constantly call the solicitors for an update. Not only can we source an excellent solicitor for you, we can help you complete the legal paperwork, we are here to help with legal side of your mortgage.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
          );
      }
 }

 export default About;
