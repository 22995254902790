import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import './content.scss';

class Content extends Component {
    render() {
      return (
          <>
              <section id="conveyancing" className="section-standard">
                    <Container>
                        <h2 className="mb-3 text-center section-title-dark">Conveyancing</h2>
                        <div className="section-header-separator"></div>
                        <div className="about-text text-gray">
                            <div className="mb-5">
                                If you are moving home or remortgaging why not use our comparison tool to source the best conveyancing costs
                                <br/><br/>
                                Service Promise:
                                <ul>
                                    <li>Conveyancing costs can be cheaper than going to solicitors direct</li> 
                                    <li>There will not be a professional fee if your transaction does not go ahead</li>
                                    <li>All solicitors have agreed to rigorous service standards specified by ConveyancingBrain</li>
                                    <li>Service is constantly monitored to ensure all conveyancing work meets the highest standards</li>
                                    <li>Your chosen solicitor will be able to start work immediately</li>
                                    <li>The price you see is the price you pay</li>
                                </ul>
                            </div>
                        </div>
                    </Container>
                </section>
                <section id="conveyancing-two" className="section-standard">
                    <Container className="vertical-center">
                        <div className="w-100">Conveyancing is not regulated by the Financial Conduct Authority.</div>
                    </Container>
                </section>
            </>
      );
    }
 }

 export default Content;
