import React from 'react';
import Header from './Header/Header.js';
import Content from './Content/Content.js';
import ContactBanner from '../ContactBanner/ContactBanner.js';
import Repossessed from '../Repossessed/Repossessed.js';

function Mortgages() {
    return (
        <div>
            <Header/>
            <Content/>
            <Repossessed/>
            <ContactBanner desc="Contact us now to get your home move moving!"/>
        </div>
    );
}

export default Mortgages;
