import React, { Component } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import ModalPaymentCalculator from '../ModalPaymentCalculator/ModalPaymentCalculator.js';
import ModalBorrowCalculator from '../ModalBorrowCalculator/ModalBorrowCalculator.js';

import './calculator.scss';

class Calculator extends Component {

    constructor(props) {
        super(props);
        this.state = { showPaymentCalculator: false, showBorrowCalculator: false }
    }

    mortgageClick = () => {
        this.props.history.push('/mortgages')
    }

    setModalShowPayment = (value) => {
        this.setState({ showPaymentCalculator: value });
    }

    setModalShowBorrow = (value) => {
        this.setState({ showBorrowCalculator: value });
    }

    render() {
      const { showPaymentCalculator, showBorrowCalculator } = this.state;

      return (
          <section id="calculator" className="section-standard">
                <Container>
                    <span><h2 className="mb-3 text-center">Mortgage Calculators</h2></span>
                    <div className="section-header-separator"></div>
                    <div className="text-center mb-5 text-gray">Try out our mortgage calculators and find out what you can afford</div>
                    <Row>
                        <Col className="mb-3 mb-lg-0 text-center">
                            <button className="btn btn-calculator" onClick={() => this.setModalShowPayment(true)}>How much can I pay?</button>
                        </Col>
                        <Col className="mb-3 mb-lg-0 text-center">
                            <button className="btn btn-calculator" onClick={() => this.setModalShowBorrow(true)}>How much can I borrow?</button>
                        </Col>
                        <Col className="text-center">
                            <button className="btn btn-calculator" onClick={this.mortgageClick}>Find out if you qualify for a mortgage</button>
                        </Col>
                    </Row>
                    <ModalPaymentCalculator
                        show={showPaymentCalculator}
                        onHide={() => this.setModalShowPayment(false)}
                    />
                    <ModalBorrowCalculator
                        show={showBorrowCalculator}
                        onHide={() => this.setModalShowBorrow(false)}
                    />
                </Container>
            </section>
      );
    }
 }

 export default withRouter(Calculator)
