import React, { Component } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import ModalPaymentCalculator from '../../ModalPaymentCalculator/ModalPaymentCalculator.js';
import ModalBorrowCalculator from '../../ModalBorrowCalculator/ModalBorrowCalculator.js';
import './content.scss';

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = { showPaymentCalculator: false, showBorrowCalculator: false }
    }

    mortgageClick = () => {
        this.props.history.push('/mortgages')
    }

    setModalShowPayment = (value) => {
        this.setState({ showPaymentCalculator: value });
    }

    setModalShowBorrow = (value) => {
        this.setState({ showBorrowCalculator: value });
    }

      render() {
        const { showPaymentCalculator, showBorrowCalculator } = this.state;

        return (
              <>
                  <section id="mortgages-content" className="section-standard">
                        <Container>
                            <h2 className="mb-3 text-center section-title-dark">How we can help you</h2>
                            <div className="section-header-separator"></div>
                            <div className="about-text text-gray">
                                <div className="mb-5">
                                    It is difficult to choose the right mortgage for your future home or even to choose a new deal on your existing home. With thousands of different options available and each lender having different requirements and rules we help you find the right mortgage for your budget, unique circumstances and needs and provide expert unbiased advice and guidance.
                                    <br/><br/>
                                    Whether it’s a mortgage for your new or existing home, a buy to let mortgage for a property you want to rent out or a mortgage for a property you want to build, a self-build mortgage, we will also provide you with different options to make sure you can still afford your monthly mortgage payments in case rates start to increase. 
                                </div>
                                <Card className="warning-card">
                                    Potentially you could borrow anywhere between 3 - 5 times your salary (if you're buying as a couple it'll be more like 4 times your combined incomes).
                                </Card>
                                <br/><br/>
                                <div className="mb-4">The quickest way to discover the maximum you can comfortably borrow is by speaking to an experienced mortgage  broker, such as ourselves, and getting them to check out all available mortgage deals, that way you can be sure you get a competitive deal.</div>
                            </div>
                        </Container>
                    </section>
                    <section id="mortgages-calc" className="section-standard vertical-center">
                        <Container className="text-center">
                            <Row>
                                <Col md={{span: 4, offset: 2}} className="mb-4 mb-md-0 text-center">
                                    <button className="btn" onClick={() => this.setModalShowPayment(true)}>How much can I pay?</button>
                                </Col>
                                <Col md={{span: 4}} className="mb-3 mb-md-0 text-center">
                                    <button className="btn" onClick={() => this.setModalShowBorrow(true)}>How much can I borrow?</button>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <ModalPaymentCalculator
                        show={showPaymentCalculator}
                        onHide={() => this.setModalShowPayment(false)}
                    />
                    <ModalBorrowCalculator
                        show={showBorrowCalculator}
                        onHide={() => this.setModalShowBorrow(false)}
                    />
                </>
          );
      }
 }

 export default Content;
